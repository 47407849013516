<template>
    <div class="text-center">
        <router-link  class="default-btn" :to="{ name: 'CourseLesson',params: {id:  lesson.id}}" v-if="lesson">Next Lesson</router-link>
        <router-link  class="default-btn" :to="{ name: 'CourseCompleted',params: {id:  chapter.course_id}}" v-else>Complete Course</router-link>
    </div>  
</template>

<script>
import axios from 'axios'
export default {
    props: ['course_content_id',],
    data(){
        return {
        lesson: {},
        chapter: {},
        token: localStorage.getItem('user_data'),
        }
    },
    methods:{
        nextLesson(){
            axios.post('https://apitraining.vipawaworks.com/api/lesson/get_next_lesson/' + this.course_content_id).then(response => {
                this.lesson = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getChapter(){
            axios.get('https://apitraining.vipawaworks.com/api/course_content/profile/' + this.course_content_id).then(response => {
                this.chapter = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
    },
    created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getChapter()
    this.nextLesson()
  }
}
</script>

<style>

</style>